<template>
    <v-card :loading="loading">
        <v-card-title>
            <v-btn small icon @click="close" color="primary" class="mr-2">
                <v-icon>
                    mdi-close
                </v-icon>
            </v-btn>
            <h3>New Packing List</h3>
        </v-card-title>
        <v-divider class="ml-4 mr-5" />
        <v-card-text>
            <v-row no-gutters>
                <v-col cols="12">
                    <v-combobox
                        v-model="project"
                        :items="availableProjects"
                        item-text="longName"
                        hide-details
                        prepend-icon="mdi-account-hard-hat"
                        placeholder="Select a Project: "
                    >
                    </v-combobox>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions>
            <v-spacer />
            <v-btn
                color="primary"
                class="mb-4"
                @click="selectProject"
                rounded
                :loading="loading"
                :disabled="!project"
            >
                NEXT
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
    name: 'CreatePackingList',
    props: {
        projects: {
            type: Array,
            required: true,
            default: () => [],
        },
        onlySuggested: {
            type: Boolean,
            required: true,
            default: () => true,
        },
        currentUser: {
            type: Object,
            required: true,
            default: () => {},
        },
    },
    data: () => ({
        loading: false,
        project: null,
        availableProjects: [],
    }),
    async mounted() {
        try {
            this.loading = true
            this.projects.forEach(
                project =>
                    (project.longName =
                        project.reference + ' - ' + project.name)
            )
            this.availableProjects = this.projects
            if (this.onlySuggested) {
                this.availableProjects = this.projects.filter(
                    project => project.projectManager == this.currentUser.id
                )
            }
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        async selectProject() {
            try {
                this.$emit('selectProject', this.project)
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        close() {
            this.$emit('close')
        },
    },
}
</script>

<style></style>
